import { RadioGroup } from '@headlessui/react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

export default function TypeSelect({ type, setType }) {
  const { t } = useTranslation();
  return (
    <div className="p-4  font-bold uppercase text-sm">
      <RadioGroup value={type} onChange={setType}>
        <RadioGroup.Label className="sr-only">Location Type</RadioGroup.Label>

        <div className="rounded-md border-2 p-1 flex justify-between gap-2">
          <RadioGroup.Option value="All Locations">
            {({ checked }) => (
              <span
                className={classNames(
                  checked ? 'bg-nfnGreen-button' : '',
                  'p-4 py-3 rounded-md inline-block'
                )}>
                {t('ALL LOCATIONS')}
              </span>
            )}
          </RadioGroup.Option>
          <RadioGroup.Option value="Retail">
            {({ checked }) => (
              <span
                className={classNames(
                  checked ? 'bg-nfnGreen-button' : '',
                  'p-4 py-3 rounded-md inline-block'
                )}>
                {t('RETAIL')}
              </span>
            )}
          </RadioGroup.Option>
          <RadioGroup.Option value="CardLock">
            {({ checked }) => (
              <span
                className={classNames(
                  checked ? 'bg-nfnGreen-button' : '',
                  'p-4 py-3 rounded-md inline-block'
                )}>
                {t('CARDLOCK')}
              </span>
            )}
          </RadioGroup.Option>
        </div>
      </RadioGroup>
    </div>
  );
}
